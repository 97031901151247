/**
 * @module constants/routes
 * @desc exported const data representing the front end routes
 */
export const ROOT_ROUTE = "/";

//////////////////////////////////////////////////////////////////////
// LOGIN ROUTES //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
export const LOGIN_ROUTE = "/login";

export const UNREGISTERED_ROUTE = '/unregistered';

//////////////////////////////////////////////////////////////////////
// TAGS ROUTES ///////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
export const TAG_ROUTE_PREFIX = "/tag/";
export const TAG_ROUTE = TAG_ROUTE_PREFIX + ":id";

export const TAG_LIST_ROUTE = "/admin/tags";

export const TAG_EDIT_ROUTE_PREFIX = "/admin/tags/edit/";
export const TAG_EDIT_ROUTE = TAG_EDIT_ROUTE_PREFIX + ":id";

export const TAG_DELETE_ROUTE_PREFIX = "/admin/tags/delete/";
export const TAG_DELETE_ROUTE = TAG_DELETE_ROUTE_PREFIX + ":id";

export const TAG_CREATE_ROUTE = "/admin/tags/create/";
//////////////////////////////////////////////////////////////////////
// CATEGORY ROUTES ///////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
export const CATEGORY_ROUTE_PREFIX = "/category/";
export const CATEGORY_ROUTE = CATEGORY_ROUTE_PREFIX + ":id";

export const CATEGORY_LIST_ROUTE = "/admin/categories";

export const CATEGORY_EDIT_ROUTE_PREFIX = "/admin/categories/edit/";
export const CATEGORY_EDIT_ROUTE = CATEGORY_EDIT_ROUTE_PREFIX + ":id";

export const CATEGORY_CREATE_ROUTE = "/admin/categories/create/";
//////////////////////////////////////////////////////////////////////
// USERS ROUTES///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
export const USERS_ROUTE = "/admin/users";

export const USERS_DELETE_ROUTE_PREFIX = "/admin/users/delete/";
export const USERS_DELETE_ROUTE = USERS_DELETE_ROUTE_PREFIX + ":id";

export const USERS_EDIT_ROUTE_PREFIX = "/admin/users/edit/";
export const USERS_EDIT_ROUTE = USERS_EDIT_ROUTE_PREFIX + ":id";

//////////////////////////////////////////////////////////////////////
// ISSUES ROUTES /////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
export const ISSUE_ROUTE_PREFIX = "/issue/";
export const ISSUE_ROUTE = ISSUE_ROUTE_PREFIX + ":id";
export const ISSUE_LIST_ROUTE = "/admin/issues";
export const ISSUE_CREATE_ROUTE = "/admin/issues/create";

export const ISSUE_VERSION_ROUTE_PREFIX = "/version/";
export const ISSUE_VERSION_ROUTE = ISSUE_ROUTE + ISSUE_VERSION_ROUTE_PREFIX + ":versionId";

//////////////////////////////////////////////////////////////////////
// SEARCH ROUTES /////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
export const SEARCH_ROUTE = "/search/";

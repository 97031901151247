import React from "react";
import Auth from '../../auth';
import LoginWrapper from './login-wrapper';
import { Button } from '../../components/widgets';

const Unregistered = props => (
  <LoginWrapper>
    <div className="unregistered-text-container">
      <p>Your account has been created.<br />
        Please note that your registration is not complete until your administrator has approved your account.</p>
    </div>
    <Button
      className="login-button"
      name="Sign Out"
      label="Sign Out"
      onClick={() => Auth.signOut()}
    >Sign Out</Button>
  </LoginWrapper>
);

export default Unregistered;

import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import { ROOT_ROUTE, USERS_ROUTE } from "../../constants/routes";
import "./style.scss";

import { isSystemAdmin, isLocationAccessCoordinator, getRoleName } from "../../utils";

import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors";

import Auth from '../../auth';

const mapStateToProps = (state, props) => {
  return {
    currentUser: getCurrentUser(state)
  };
};

class Usermenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggleMenu = e => {
    const { isOpen } = this.state;
    if (this.node.contains(e.target)) {
      this.setState({
        isOpen: !isOpen
      });
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.toggleMenu);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.toggleMenu);
  }

  render() {
    const { currentUser } = this.props;
    const { isOpen } = this.state;

    const roleName = getRoleName(currentUser.role);

    return (
      <Fragment>
        <div className="content">
          <div className="user-menu" ref={node => (this.node = node)}>
            <div className="user-status">
              <nav role="navigation">
                <div className="large-nav">
                  <i className="fas fa-user fa-md pr_1" />
                  <span
                    className="name"
                    tabIndex="0"
                    aria-expanded="false"
                    aria-label="usermenu"
                    onKeyPress={this.toggleMenu}
                  >
                    {currentUser.name}
                  </span>
                  {isOpen ? (
                    <i className="fas fa-caret-down rotate180" />
                  ) : (
                    <i className="fas fa-caret-down unrotate180" />
                  )}
                </div>
                <div id="menuToggle">
                  <input
                    className="cross-menu"
                    type="checkbox"
                    checked={isOpen}
                    readOnly
                  />
                  <span />
                  <span />
                  <span />
                  <div
                    className={isOpen ? "submenu show-menu" : " submenu hide"}
                  >
                    <div className="small-nav">
                      <i className="fas fa-user fa-md pr_1" />
                      <div
                        className="name"
                        aria-expanded="false"
                        aria-label="usermenu"
                      >
                        {currentUser.name}
                      </div>
                    </div>
                    <span className="user-role mobile-role">{roleName}</span>
                    <ul aria-expanded={isOpen ? "true" : "false"} id="menu">
                      {(isSystemAdmin(currentUser) || isLocationAccessCoordinator(currentUser)) && (
                        <li>
                          <Link to={USERS_ROUTE}>
                            <i className="fas fa-cog" />
                            Dashboard
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          to={ROOT_ROUTE}
                          onClick={() => Auth.signOut()}
                          onBlur={isOpen ? this.toggleMenu : null}
                        >
                          <i className="fas fa-sign-out-alt" />
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect( mapStateToProps)(Usermenu);

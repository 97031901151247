/**
 * @module selectors/users
 * @desc Selectors for user data
 */

import { createSelector } from 'reselect';
import { getRoleName } from "../utils";

const makeUserFromData = userData => {
  const data = {...userData};
  if (data.Attributes) {
    data.Attributes.forEach(attr => {
      data[attr.Name] = attr.Value;
    });
  }

  const user = {
    firstName: data.given_name || "",
    lastName: data.family_name || "",
    name: `${data.given_name} ${data.family_name}`,
    email: data.email,
    role: data.role || data.groupName,
    confirmed: data.role || data.groupName,
    roleName: getRoleName(data.role || data.groupName) || '- UNREGISTERED -',
    campusId: data["custom:campus_id"] || data.campus_id,
    // the || is to handle the differences between our lambda api user data and the cognito api user data
    id: data.Username || data.sub
  };


  return user;
}

const campusListData = state => state.campuses.data;
const currentUserData = state => state.session.currentUser;

/**
 * Gets a formatted version of the current user from the store
 * @function
 * @param {Object} state
 * @returns {Object} user
 * @returns {string} user.firstName
 * @returns {string} user.lastName
 * @returns {string} user.name
 * @returns {string} user.email
 * @returns {string} user.role
 */
export const getCurrentUser = createSelector(
  [ currentUserData, campusListData ],
  (currentUser, campusList) =>  {
    if (!currentUser) {
      return null;
    }
    const user = makeUserFromData(currentUser);
    const campus = campusList.find(c => c.id === user.campusId);
    user.campus = campus;

    return user;
  }
);

const userListData = state => state.users.list.data;

export const getUserList = createSelector(
  [userListData, campusListData],
  (userList, campusList) => userList.map(u => {
    const user = makeUserFromData(u);
    const campus = campusList.find(c => c.id === user.campusId);
    user.campus = campus;

    return user;
  })
);

/**
 * Returns a selector function to get a specific user's data
 * @function
 * @param {string} id
 * @returns {function(id)} selector function
 * @example const user = getUserById(userId)(state);
 */
export const getUserById = id => createSelector(
  [getUserList],
  userList => {
    const user = userList.find(u => u.id === id);
    if (!user) {
      return {};
    }

    return user;
  }
);

import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { LOGIN_ROUTE, UNREGISTERED_ROUTE, ROOT_ROUTE } from "../../constants/routes";

import { connect } from "react-redux";
import { verifyCurrentUserSession } from '../../actions';

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.session.currentUser
  };
};

// This component is used to prevent logged out or unregistered users from accessing any routes
// that require a registered user and an active session to function.
// This component will also prevent users who do not have the correct role (allowedRoles prop)
// from accessing the route.
class AuthenticatedRoute extends Component {

  componentDidMount() {
    this.props.verifyCurrentUserSession();
  }

  componentDidUpdate() {
    this.props.verifyCurrentUserSession();
  }

  get isAuthenticated() {
    const { currentUser } = this.props;
    return Boolean(currentUser);
  }

  get isRegistered() {
    const { currentUser } = this.props;
    return Boolean(this.isAuthenticated && currentUser.role);
  }

  get isAuthorized() {
    const { allowedRoles, currentUser } = this.props;
    if (!allowedRoles) {
      return this.isRegistered;
    }

    return this.isRegistered && allowedRoles.find(role => currentUser.role === role);
  }

  targetComponent(props) {
    const { component: Component } = this.props;

    if (!this.isAuthenticated) {
      return <Redirect to={{pathname: LOGIN_ROUTE}} />
    }

    if (!this.isRegistered) {
      return <Redirect to={{pathname: UNREGISTERED_ROUTE}} />
    }

    if (!this.isAuthorized) {
      return <Redirect to={{pathname: ROOT_ROUTE}} />
    }

    return <Component {...props} />;
  }

  render() {
    // destructuring some extra variables to clean up ...rest a bit
    const { allowedRoles, children, component, currentUser, ...rest } = this.props;
    return (
      <Route {...rest} render={props => this.targetComponent(props)}>
        {children}
      </Route>
    );

  }
}

export default connect(
  mapStateToProps,
  { verifyCurrentUserSession }
)(AuthenticatedRoute);

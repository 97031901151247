/**
 * @module constants/fragments
 * @desc exported cost data pertaining to longer bits of text and jsx fragments that we don't want to include inline in a component
 */

import React, { Fragment } from 'react';

export * from './user-fragments';
export * from './category-fragments';
export * from './tag-fragments';

export const WELCOME_BLURB = (
  <Fragment>
    <p>Sit cupiditate qui id placeat vel? Quis alias necessitatibus quam ducimus sed, laboriosam. Modi porro rem mollitia eligendi sapiente Laudantium nulla eligendi aliquam odit praesentium sapiente? Dolorum inventore ad aut.</p>
    <p>Sit maxime nostrum totam deserunt tempore? Placeat autem autem odit odio maxime suscipit illo hic voluptatum? Explicabo illum autem alias perspiciatis numquam suscipit natus modi corrupti. Dolorem rerum necessitatibus iste</p>
  </Fragment>
);


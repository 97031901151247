/**
 * @module actions/user-actions
 * @desc Redux and thunk actions to handle user CRUD
 */
import {
  LOAD_USER_LIST,
  SET_USER_LIST_LOADING_STATUS,
  SET_USER_ACTION_LOADING_STATUS
} from '../constants/action-types';

import {
  makeGenericErrorMessage
} from '../constants/errors';

import {
  USERS_ROUTE
} from '../constants/routes';

import {
  makeEditUserSuccessMessage,
  makeDeleteUserSuccessMessage
} from '../constants/fragments';

import {
  validateUpdateUserFormData,
  validateDeleteUserFormData
} from '../utils';

import {
  fetchUserList,
  updateUser,
  deleteUser
} from '../api';

import {
  getUserById
} from '../selectors';

import history from '../history';

import {
  setErrors,
  resetAllMessages,
  setSuccessMessage,
  verifyCurrentUserSession
} from './';

const loadUserList = users => ({
  type: LOAD_USER_LIST,
  users
});

const setUserListLoadingStatus = isLoading => ({
  type: SET_USER_LIST_LOADING_STATUS,
  isLoading
});

const setUserActionLoadingStatus = isLoading => ({
  type: SET_USER_ACTION_LOADING_STATUS,
  isLoading
});

/**
 * Action to retrieve the list of users and load it into the store
 * @function
 */
export const fetchAndLoadUserList = () => (dispatch, getState) => {
  dispatch(setUserListLoadingStatus(true));
  fetchUserList().then(res => {
    dispatch(loadUserList(res));
  }).catch(errors => {
      dispatch(setErrors(errors));
  }).finally(() => {
    dispatch(setUserListLoadingStatus(false));
  });
};


/**
 * Makes an api call to update a user's attributes and role/group
 * @function
 * @param {object} formData
 * @param {string} formData.id
 * @param {string} formData.firstname
 * @param {string} formData.lastname
 * @param {string} formData.role
 * @param {string} formData.campus
 */
export const attemptToUpdateUser = formData => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const campusList = getState().campuses.data;
  if (campusList.length === 0) {
    // we should never actually arrive here but we can handle it if we do for some reason
    dispatch(setErrors(makeGenericErrorMessage({context: 'update user', error: 'no campus list'})));
    return;
  }

  const { id } = formData;
  const getUser = getUserById(id);
  const user = getUser(getState());

  const errors = validateUpdateUserFormData(formData, campusList);
  if (errors.length) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setUserActionLoadingStatus(true));
  updateUser({...formData, email: user.email}).then(res => {
    dispatch(
      setSuccessMessage(
        makeEditUserSuccessMessage(user)
      )
    );
    history.replace(USERS_ROUTE);
  }).catch(error => {
    dispatch(setErrors(error));
  }).finally(() => {
    dispatch(fetchAndLoadUserList());
    dispatch(setUserActionLoadingStatus(false));
    dispatch(verifyCurrentUserSession());
  });
};

/**
 * Makes an api call to delete a user from the userpool
 * @function
 * @param {object} formData
 * @param {string} formData.id
 * @param {string} formData.email email entered in form to confirm deletion
 */
export const attemptToDeleteUser = formData => (dispatch, getState) => {
  dispatch(resetAllMessages());

  const { id } = formData;
  const getUser = getUserById(id);
  const user = getUser(getState());

  const errors = validateDeleteUserFormData(formData, user.email);
  if (errors.length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  dispatch(setUserActionLoadingStatus(true));
  deleteUser(id).then(res =>{
    dispatch(
      setSuccessMessage(
        makeDeleteUserSuccessMessage(user)
      )
    );
    history.replace(USERS_ROUTE);
  }).catch(error => {
    dispatch(setErrors(error));
  }).finally(() => {
    dispatch(fetchAndLoadUserList());
    dispatch(setUserActionLoadingStatus(false));
  })
};

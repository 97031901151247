import React, { Component } from "react";
import "./style.scss";

import { CategoryList, PageContent, PageContainer } from "../../components";
import { resetAllMessages } from "../../actions";
import store from "../../store";

//import { WELCOME_BLURB } from "../../constants/fragments";

class Welcome extends Component {
  componentDidMount() {
    store.dispatch(resetAllMessages());
  }

  render() {
    return (
      <PageContainer className="welcome-screen">
        <CategoryList />
        <PageContent>
          <h1>Welcome to the Negotiator's Handbook</h1>
          {/*WELCOME_BLURB*/}
        </PageContent>
      </PageContainer>
    );
  }
}

export default Welcome;
